@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';























@import "@web/sass/variables.scss";

.wrapper {
  border-radius: 9999rem;
  background-color: $kod-black;
}
