@import "@web/sass/variables.scss";
@import "@web/sass/fonts.scss";

// Print
@media print {
  html {
    font-size: 12px;
  }
}

.v-application {
  button {
    border-radius: 0.375rem;
  }

  .v-tab {
    &:hover {
      &::before {
        opacity: 0 !important;
      }
    }
  }

  .row--dense {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .row--dense > .col,
  .row--dense > [class*="col-"] {
    padding: 0.5rem !important;
  }

  .leader-text {
    font-size: map-get(map-get($text, leader), size);
    line-height: map-get(map-get($text, leader), line-height);
  }

  .body-text {
    font-size: map-get(map-get($text, body), size);
    line-height: map-get(map-get($text, body), line-height);
  }

  .small-text {
    font-size: map-get(map-get($text, small), size);
    line-height: map-get(map-get($text, small), line-height);
  }

  .extra-small-text {
    font-size: map-get(map-get($text, extra-small), size);
    line-height: map-get(map-get($text, extra-small), line-height);
  }

  .post-extra-small-text {
    font-size: map-get(map-get($text, post-extra-small), size);
    line-height: map-get(map-get($text, post-extra-small), line-height);
  }

  .title-text {
    font-size: map-get(map-get($text, title), size);
    line-height: map-get(map-get($text, title), line-height);
    font-weight: map-get(map-get($text, title), font-weight);
    @include margin-bottom-multiplier(1);
  }

  .heading-text {
    font-size: map-get(map-get($text, heading), size);
    line-height: map-get(map-get($text, heading), line-height);
  }

  .medium-heading-text {
    font-size: map-get(map-get($text, medium-heading), size);
    line-height: map-get(map-get($text, medium-heading), line-height);
  }

  .big-heading-text {
    font-size: map-get(map-get($text, big-heading), size);
    line-height: map-get(map-get($text, big-heading), line-height);
  }

  .error-details {
    padding: $padding;
    width: 100%;

    text-align: center;

    background: $kod-light-red-accent;
    color: $kod-red;
    border-radius: 0.125rem;
  }

  // toto checknut
  .form-error-message {
    background-color: $koderia-red-accent;
    border-radius: 0.125rem;

    > p {
      @include margin-top-multiplier(4);
      @include padding-top-multiplier(3);
      @include padding-bottom-multiplier(3);
      @include padding-left-multiplier(2);
      @include padding-right-multiplier(2);
      color: $koderia-red;
    }
  }

  // kod stuff
  hr.kod-hr {
    border: unset;
    border-top: 0.063rem solid $kod-hr;
    margin-top: 0.25rem;
  }

  // custom menu
  .v-menu__content,
  .v-select-list,
  .v-select-list > div {
    border-radius: 0.125rem !important;
  }

  .v-menu__content {
    box-shadow: 0 0.063rem 0.25rem $koderia-shadow;

    .v-list-item {
      height: 2.5rem;
      min-height: unset;

      .v-ripple__container {
        display: none;
      }

      > .v-list-item__action {
        margin-top: 0;
        margin-bottom: 0;
        @include margin-right-multiplier(2);

        .v-input--selection-controls__ripple {
          margin-top: 0;
          margin-bottom: 0;
        }

        .v-icon {
          margin-top: -0.125rem;
          font-size: 1.125rem;
          // icon base
          &.far.fa-square {
            color: $kod-black;
          }

          // checked icon
          &.fas.fa-check-square {
            color: $kod-light-green !important;
          }
        }
      }

      > .v-list-item__content {
        @include padding-top-multiplier(1);
        @include padding-bottom-multiplier(1);
      }

      &.v-list-item--active,
      &:hover {
        color: $kod-black !important;
        background-color: $kod-light-grey !important;
      }

      &::before {
        opacity: 0;
      }
    }
  }

  .new-custom-autocomplete, // used in projects-listing-page
  .new-custom-select, // used in projects-listing-page,  and questionaire position select page
  .custom-autocomplete {
    // used in programmer & tester form in questionaire
    .v-input__slot {
      min-height: 2.5rem;
      padding-right: 0.25rem !important;
      box-shadow: none !important;
      border-radius: 0.125rem !important;
      background: $kod-fields-bg !important;

      .v-icon {
        padding: 0.313rem 0;
        font-size: 0.875rem;
        caret-color: $kod-black !important;
        color: $kod-black !important;
      }

      &:after,
      &:before {
        display: none;
      }
    }

    .v-select__selections {
      height: 2.5rem;

      display: flex;
      flex-direction: row;
      // justify-content: flex-start;
      flex-wrap: nowrap;

      overflow: hidden;

      ::placeholder {
        color: $kod-grey !important;
      }

      .v-chip {
        font-size: 1rem;
        height: 1.625rem;
        margin-left: 0;
        margin-right: 0.5rem;
        border-radius: 0.125rem;
        color: $kod-black !important;
        background-color: $kod-light-grey !important;
        padding: 0 0.75rem 0 0.5rem;

        .v-icon {
          color: $kod-black !important;
        }
      }

      .filter-caption {
        color: $kod-black;
      }
    }

    .v-select__slot {
      .v-input__append-inner {
        margin-top: 0 !important;
      }

      .v-input__icon.v-input__icon--append {
        padding: 0.5rem 0;
        height: 100%;
      }
    }

    &.v-input--is-label-active,
    &.v-input--is-focused {
      .v-icon {
        color: $kod-black;
      }
    }
  }

  .smaller-select {
    .v-select__slot,
    .v-select__selections {
      min-height: unset !important;
      height: 2.125rem !important;
    }
  }

  .kod-shadow {
    box-shadow: 0 0.063rem 0.25rem $koderia-shadow;
    border-radius: 0.125rem;

    transition: all ease-in 200ms;

    &.hover-shadow:hover {
      box-shadow: 0 0.063rem 1.5rem $koderia-shadow;
    }
  }

  .new-colors-table {
    margin-top: 1.875rem;
    // border-collapse: collapse;
    tr > td {
      min-width: 6.25rem !important;
      height: 6.25rem !important;
      padding: 0.625rem;
    }

    tr {
      border: 0.063rem solid;

      &:nth-child(1) {
        td:nth-child(1) {
          background-color: $kod-black;
          color: white;
        }

        td:nth-child(2) {
          background-color: $kod-grey;
        }

        td:nth-child(3) {
          background-color: $kod-whitesmoke;
        }

        td:nth-child(4) {
          background-color: $kod-light-grey;
        }
      }

      &:nth-child(2) {
        td:nth-child(1) {
          background-color: $kod-green;
        }

        td:nth-child(2) {
          background-color: $kod-light-green;
        }

        td:nth-child(3) {
          background-color: $kod-green-accent;
        }

        td:nth-child(4) {
          background-color: $kod-light-green-accent;
        }
      }

      &:nth-child(3) {
        td:nth-child(1) {
          background-color: $kod-blue;
          color: white;
        }

        td:nth-child(2) {
          background-color: $kod-light-blue;
        }

        td:nth-child(3) {
          background-color: $kod-blue-accent;
        }

        td:nth-child(4) {
          background-color: $kod-light-blue-accent;
        }
      }

      &:nth-child(4) {
        td:nth-child(1) {
          background-color: $kod-red;
          color: white;
        }

        td:nth-child(2) {
          background-color: $kod-light-red;
        }

        td:nth-child(3) {
          background-color: $kod-red-accent;
        }

        td:nth-child(4) {
          background-color: $kod-light-red-accent;
        }
      }

      &:nth-child(5) {
        td:nth-child(1) {
          background-color: $kod-yellow;
          color: white;
        }

        td:nth-child(2) {
          background-color: $kod-light-yellow;
        }

        td:nth-child(3) {
          background-color: $kod-yellow-accent;
        }

        td:nth-child(4) {
          background-color: $kod-light-yellow-accent;
        }
      }
    }
  }
}

.kod-gdpr-checkbox {
  &:hover .small-text {
    text-decoration: none !important;
  }

  margin-left: 0.188rem;

  > div {
    &.invalid {
      .kod-checkbox i.v-icon,
      span.small-text,
      span.small-text > a {
        color: $koderia-red-alternative !important;
      }
    }

    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: flex-start;

    &:first-child {
      @include margin-bottom-multiplier(1);
    }

    span {
      @include margin-left-multiplier(0);
      user-select: none;

      > a {
        color: $koderia-black;
      }
    }

    cursor: pointer;
  }
}

// Utilities
.min-w-full {
  min-width: 100% !important;
}

.min-h-full {
  min-height: 100% !important;
}

.kod-link {
  color: $kod-black !important;
}

.kod-link:hover {
  color: $kod-green !important;
}

.kod-link-primary {
  color: $kod-green !important;
}

.kod-link-primary:hover {
  color: $kod-black !important;
}

.v-chip--label {
  border-radius: 0.125rem !important;
}

.hidden {
  opacity: 0.5;
}

@media (min-width: 64rem) {
  .hidden-desktop {
    display: none !important;
  }
}

@media (max-width: 63.938rem) {
  .hidden-mobile {
    display: none !important;
  }

  .v-application {
    // h1 {
    //     font-size: map-get(map-get($smallHeadings, h1), size);
    //     line-height: map-get(map-get($smallHeadings, h1), line-height);
    //     font-weight: 800;
    // }
    // h2 {
    //     font-size: map-get(map-get($smallHeadings, h2), size);
    //     line-height: map-get(map-get($smallHeadings, h2), line-height);
    //     font-weight: 800;
    // }
    // h3 {
    //     font-size: map-get(map-get($smallHeadings, h3), size);
    //     line-height: map-get(map-get($smallHeadings, h3), line-height);
    //     font-weight: 800;
    // }
    // h4 {
    //     font-size: map-get(map-get($smallHeadings, h4), size);
    //     line-height: map-get(map-get($smallHeadings, h4), line-height);
    //     font-weight: 800;
    // }
    // h5 {
    //     font-size: map-get(map-get($smallHeadings, h5), size);
    //     line-height: map-get(map-get($smallHeadings, h5), line-height);
    //     font-weight: 800;
    // }

    .leader-text {
      font-size: map-get(map-get($smallText, leader), size);
      line-height: map-get(map-get($smallText, leader), line-height);
    }

    .body-text {
      font-size: map-get(map-get($smallText, body), size);
      line-height: map-get(map-get($smallText, body), line-height);
    }

    .small-text {
      font-size: map-get(map-get($smallText, small), size);
      line-height: map-get(map-get($smallText, small), line-height);
    }

    .extra-small-text {
      font-size: map-get(map-get($smallText, extra-small), size);
      line-height: map-get(map-get($smallText, extra-small), line-height);
    }

    .post-extra-small-text {
      font-size: map-get(map-get($smallText, post-extra-small), size);
      line-height: map-get(map-get($smallText, post-extra-small), line-height);
    }

    .title-text {
      font-size: map-get(map-get($smallText, title), size);
      line-height: map-get(map-get($smallText, title), line-height);
      font-weight: map-get(map-get($smallText, title), font-weight);
      @include margin-bottom-multiplier(1);
    }

    .heading-text {
      font-size: map-get(map-get($smallText, heading), size);
      line-height: map-get(map-get($smallText, heading), line-height);
    }

    .medium-heading-text {
      font-size: map-get(map-get($smallText, medium-heading), size);
      line-height: map-get(map-get($smallText, medium-heading), line-height);
    }

    .big-heading-text {
      font-size: map-get(map-get($smallText, big-heading), size);
      line-height: map-get(map-get($smallText, big-heading), line-height);
    }
  }
}

.sr-only {
  position: absolute;
  width: 0.063rem;
  height: 0.063rem;
  padding: 0;
  margin: -0.063rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.break-inside-avoid {
  page-break-inside: avoid;
  break-inside: avoid;
  -webkit-column-break-inside: avoid;
  -webkit-region-break-inside: avoid;
  // outline: salmon 1px dashed;
}


#cc-main {
  /** Change button primary color to black **/
  --cc-btn-primary-bg: #0f9c5d;
  --cc-btn-primary-border-color: #0f9c5d;
  --cc-btn-primary-hover-bg: #0b7e4a;
  --cc-btn-primary-hover-border-color: #0b7e4a;
}
