@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';




























































.menu {
  top: unset;
}

.lang-wrapper-mobile {
  column-gap: 30px;
}

.lang-btn {
  border: none;
  background-color: transparent;

  &:focus,
  &:focus-visible {
    outline: none !important;
  }
}

.lang-btn-active {
  color: $koderia;
}
