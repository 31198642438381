@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';
































.lang-btn {
  border: none;
  background-color: transparent;
  text-align: left;

  &:focus,
  &:focus-visible {
    outline: none !important;
  }
}

.lang-btn-active {
  color: $koderia;
}
