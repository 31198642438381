/* Override Vuetify base styles */
.v-application p {
  margin-bottom: unset;
}

.v-application code {
  padding: inherit;
  color: inherit;
  background-color: inherit;
}

@tailwind base;

.new-chip {
  @apply tw-inline-flex tw-px-3 tw-py-2 tw-font-mono tw-leading-none tw-bg-white tw-text-gray-700 tw-rounded-sm tw-uppercase;
}

.new-chip--gray {
  @apply tw-bg-gray-100;
}

.new-chip--dark-gray {
  @apply tw-bg-gray-700 tw-text-white;
}

.new-chip--sm {
  @apply tw-px-2 tw-py-1 tw-text-sm tw-tracking-wide;
}

.new-chip--xs {
  @apply tw-px-2 tw-py-0 tw-text-xs tw-tracking-wider;
}

@tailwind components;

/* Gradients */
.tw-bg-gradient-green {
  background-image: linear-gradient(63deg, #018956 0%, #0ea66d 100%);
}

.tw-bg-gradient-blue {
  background-image: linear-gradient(72deg, #3369c1 0%, #4286f4 100%);
}

.tw-bg-gradient-orange {
  background-image: linear-gradient(80deg, #ff5e62 0%, #f7874c 100%);
}

@tailwind utilities;

.scrollbar-none {
  scrollbar-visibility: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollbar-none::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.custom-horizontal-scrollbar {
  scrollbar-color: transparent transparent;
}

.custom-horizontal-scrollbar:hover {
  scrollbar-color: #d1d5db transparent;
}

.custom-horizontal-scrollbar::-webkit-scrollbar {
  height: 8px;
  opacity: 0;
}

.custom-horizontal-scrollbar::-webkit-scrollbar-button {
  display: none;
}

.custom-horizontal-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #d1d5db;
  display: none;
}

.custom-horizontal-scrollbar::-webkit-scrollbar-track {
  border-radius: 50px;
}

.custom-horizontal-scrollbar:hover::-webkit-scrollbar {
  opacity: 1;
}

.custom-horizontal-scrollbar:hover::-webkit-scrollbar-thumb {
  display: block;
}

@media screen and (max-width: 767px) {
  .custom-horizontal-scrollbar::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .custom-horizontal-scrollbar {
    padding-bottom: 0.25rem;
  }
}

@-moz-document url-prefix() {
  .custom-horizontal-scrollbar {
    padding-bottom: 0;
  }
}
