@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';















































.language-switcher {
  font-size: 0.875rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
  display: inline-block;
}
