@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';





















































.dialog {
  padding: 4rem;
  border-radius: 0.125rem !important;
}

.has-ruler {
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 0.063rem solid #ededf1;
}
