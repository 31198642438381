@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';














































.btn-wrapper {
  position: relative;
  z-index: 10;
  display: inline-flex;

  .shadow {
    display: none;
    border-radius: 0.375rem;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.primary,
.primary-inverted,
.secondary {
  & > .shadow {
    display: block;
  }


  &.disabled {
    & > button {
      border: 1px solid $koderia-gray-400;
    }

    & > .shadow {
      box-shadow: 0 3px 0 0 $koderia-gray-400;
    }
  }
}

.primary,
.primary-inverted {
  & > .shadow {
    box-shadow: 0 3px 0 0 $koderia-green;
  }

  &:hover {
    & > .shadow {
      box-shadow: 0 3px 0 0 $koderia-green-dark;
    }
  }

  &:active {
    & > .shadow {
      box-shadow: 0 3px 0 0 $koderia-green;
    }
  }
}

.secondary {
  & > .shadow {
    box-shadow: 0 3px 0 0 $koderia-gray-400;
  }

  &:hover {
    & > .shadow {
      box-shadow: 0 3px 0 0 $koderia-gray-500;
    }
  }

  &:active {
    & > .shadow {
      box-shadow: 0 3px 0 0 $koderia-gray-400;
    }
  }
}
