@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';






























































































.k-input__label {
  font-size: 0.875rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
  display: inline-block;
  color: $koderia-gray-800;
}

.k-input-required {
  &::after {
    content: "*";
    color: $koderia-red;
    margin-left: 0.25rem;
  }
}

.k-input.v-input {
  * {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease;
  }

  .v-label {
    color: #999999 !important;
  }

  .v-messages.error--text {
    color: #eb3300;
  }

  input,
  .v-label {
    margin: 0 !important;
    padding: 0 !important;
  }

  &.k-input--invalid,
  &.error--text {
    .v-input__slot {
      background: #fff !important;
      box-shadow: 0 0 0 4px #eb330033 !important;
      border: 1px solid #eb3300 !important;
    }
  }

  &.v-input--is-focused {
    .v-input__slot {
      background: #fff !important;
      border: 1px solid #1f2937 !important;
      box-shadow: 0 0 0 4px #f3f4f6 !important;
    }
  }

  &.v-input--is-disabled {
    .v-input__slot {
      background: #fff !important;
      border: 1px solid #d1d5db !important;
    }
  }

  &:not(.v-input--is-focused) {
    .v-input__slot:hover {
      background: #fff !important;
      border: 1px solid #d1d5db !important;
      box-shadow: 0 0 0 4px #f3f4f6 !important;
    }
  }

  .v-input__slot {
    padding: 0.625rem 0.875rem !important;
    background: $koderia-gray-100 !important;
    border: 1px solid $koderia-gray-100 !important;
    border-radius: 0.375rem !important;
    min-height: 2.875rem !important;
  }

  &.k-input--white {
    .v-input__slot {
      background: #fff !important;
    }

    &:not(.v-input--is-focused) {
      .v-input__slot {
        border: 1px solid #d1d5db !important;
      }
    }
  }
}
